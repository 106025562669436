<template>
  <section class="section-block">
    <SectionTitle
      :title="displayData.title"
      :btn="displayData.btn.edit"
      @edit="modal.edit = true" />
    <BaseElForm
      label-position="left"
      label-width="250px"
    >
      <BaseElFormItem :label="displayData.orderServiceAttachClientCheck.title" class="settings-item">
        <span data-cy="use-notspecify-view" class="content">{{ displayData.orderServiceAttachClientCheck.value }}</span>
      </BaseElFormItem>
      <BaseElFormItem v-if="showControl.orderServiceAttachClientNullOption" :label="displayData.orderServiceAttachClientNullOption.title" class="settings-item">
        <span data-cy="use-notspecify-view" class="content">{{ displayData.orderServiceAttachClientNullOption.value }}</span>
      </BaseElFormItem>
      <BaseElFormItem v-if="showControl.orderServiceAttachClientNullOptionPosition" :label="displayData.orderServiceAttachClientNullOptionPosition.title" class="settings-item">
        <span data-cy="use-notspecify-view" class="content">{{ displayData.orderServiceAttachClientNullOptionPosition.value }}</span>
      </BaseElFormItem>
      <BaseElFormItem :label="displayData.orderServiceAttachClientMultipleSelection.title" class="settings-item">
        <span data-cy="use-notspecify-view" class="content">{{ displayData.orderServiceAttachClientMultipleSelection.value }}</span>
      </BaseElFormItem>
    </BaseElForm>

    <EditReservationAttachSettingsModal
      v-if="modal.edit"
      data-testid="edit-reservation-attach-settings-modal"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import { noSpecifyUnitOrderOptionConfig } from '@/config/reservation'
import EditReservationAttachSettingsModal from './EditReservationAttachSettingsModal.vue'
import { get } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'
export default defineComponent({
  name: 'AttachServiceSettings',
  components: {
    EditReservationAttachSettingsModal
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    const modal = reactive({ edit: false })

    const displayData = computed(() => {
      const data = {
        title: i18n.t('attachServiceSettings.title'),
        btn: {
          edit: i18n.t('common.button.edit.text'),
        },
        orderServiceAttachClientCheck: {
          title: i18n.t('attachServiceSettings.orderServiceAttachClientCheck.title'),
          value: get(props.configData, 'orderServiceAttachClientCheck') ? i18n.t('common.open.text') : i18n.t('common.close.text'),
        },
        orderServiceAttachClientNullOption: {
          title: i18n.t('attachServiceSettings.orderServiceAttachClientNullOption.title'),
          value: get(props.configData, 'orderServiceAttachClientNullOption') ? i18n.t('common.open.text') : i18n.t('common.close.text'),
        },
        orderServiceAttachClientNullOptionPosition: {
          title: i18n.t('attachServiceSettings.orderServiceAttachClientNullOptionPosition.title'),
          value: i18n.t(get(noSpecifyUnitOrderOptionConfig, `${get(props.configData, 'orderServiceAttachClientNullOptionPosition')}.label`)),
        },
        orderServiceAttachClientMultipleSelection: {
          title: i18n.t('attachServiceSettings.orderServiceAttachClientMultipleSelection.title'),
          value: get(props.configData, 'orderServiceAttachClientMultipleSelection') ? i18n.t('common.open.text') : i18n.t('common.close.text'),
        },
      }
      return data
    })
    const showControl = computed(() => {
      const controls = {
        orderServiceAttachClientNullOption: false,
        orderServiceAttachClientNullOptionPosition: false,
      }
      if (get(props.configData, 'orderServiceAttachClientCheck')) {
        controls.orderServiceAttachClientNullOption = true
        if (get(props.configData, 'orderServiceAttachClientNullOption')) {
          controls.orderServiceAttachClientNullOptionPosition = true
        }
      }

      return controls
    })
    return {
      displayData,
      modal,
      showControl,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
