<template>
  <el-dialog
    :title="displayText.title"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem :label="displayText.orderServiceAttachClientCheck.label" class="settings-item" prop="orderServiceAttachClientCheck">
        <BaseElSwitch
          v-model="formData.orderServiceAttachClientCheck"
          testName="formData_orderServiceAttachClientCheck"
          class="switch-input"
          :active-text="displayText.switch.open"
          :inactive-text="displayText.switch.close"
        />
      </BaseElFormItem>

      <BaseElFormItem v-if="showControl.orderServiceAttachClientNullOption"  class="settings-item" prop="orderServiceAttachClientNullOption">
        <template slot="label">
          <FormItemTooltipLabel :label="displayText.orderServiceAttachClientNullOption.label" :tooltipWidth="200">
            {{ displayText.orderServiceAttachClientNullOption.tooltip }}
          </FormItemTooltipLabel>
        </template>
        <BaseElSwitch
          v-model="formData.orderServiceAttachClientNullOption"
          testName="formData_orderServiceAttachClientNullOption"
          class="switch-input"
          :active-text="displayText.switch.open"
          :inactive-text="displayText.switch.close"
        />
      </BaseElFormItem>

      <BaseElFormItem v-if="showControl.orderServiceAttachClientNullOptionPosition">
        <template slot="label">
          <FormItemTooltipLabel :label="displayText.orderServiceAttachClientNullOptionPosition.label" :tooltipWidth="200">
            {{ displayText.orderServiceAttachClientNullOptionPosition.tooltip }}
          </FormItemTooltipLabel>
        </template>

        <BaseElSelect
          v-model="formData.orderServiceAttachClientNullOptionPosition"
          testName="formData_orderServiceAttachClientNullOptionPosition"
        >
          <BaseElSelectOption
            v-for="option in noSpecifyUnitOrderOptionConfig"
            :key="option.value"
            :label="$t(option.label)"
            :value="option.value"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <BaseElFormItem :label="displayText.orderServiceAttachClientMultipleSelection.label" class="settings-item" prop="orderServiceAttachClientMultipleSelection">
        <BaseElSwitch
          v-model="formData.orderServiceAttachClientMultipleSelection"
          testName="formData_orderServiceAttachClientMultipleSelection"
          class="switch-input"
          :active-text="displayText.switch.open"
          :inactive-text="displayText.switch.close"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">{{ displayText.btn.cancel }}</BaseElButton>
      <BaseElButton
        :lodading="loading"
        data-cy="edit-attach-dialog-confirm-btn"
        type="primary"
        @click="onSubmit"
      >
        {{ displayText.btn.save }}
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useBaseForm } from '@/use/useForm'
import { noSpecifyUnitOrderOptionConfig } from '@/config/reservation'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import notifyMessage from '@/config/notifyMessage'
import { UpdateReservationParameter } from '@/api/reservation'
import { useShop } from '@/use/shop'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'EditReservationNotspecifySettingsModal',
  components: {
    FormItemTooltipLabel,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()

    const formRules = computed(() => {
      const rules = {}
      return rules
    })

    const displayText = computed(() => {
      const data = {
        title: i18n.t('attachServiceSettings.title'),
        orderServiceAttachClientCheck: {
          label: `${i18n.t('attachServiceSettings.orderServiceAttachClientCheck.title')}：`,
        },
        orderServiceAttachClientNullOption: {
          label: i18n.t('attachServiceSettings.orderServiceAttachClientNullOption.title'),
          tooltip: i18n.t('attachServiceSettings.orderServiceAttachClientNullOption.tooltip')
        },
        orderServiceAttachClientNullOptionPosition: {
          label: i18n.t('attachServiceSettings.orderServiceAttachClientNullOptionPosition.label'),
          tooltip: i18n.t('attachServiceSettings.orderServiceAttachClientNullOptionPosition.tooltip')
        },
        orderServiceAttachClientMultipleSelection: {
          label: `${i18n.t('attachServiceSettings.orderServiceAttachClientMultipleSelection.title')}：`
        },
        switch: {
          open: i18n.t('common.open.text'),
          close: i18n.t('common.close.text'),
        },
        btn: {
          cancel: i18n.t('common.button.cancel.text'),
          save: i18n.t('common.button.save.text'),
        }

      }
      return data
    })
    const showControl = computed(() => {
      const controls = {
        orderServiceAttachClientNullOption: true,
        orderServiceAttachClientNullOptionPosition: true,
      }
      if (!formData.orderServiceAttachClientCheck) {
        controls.orderServiceAttachClientNullOption = false
      }
      if (!formData.orderServiceAttachClientNullOption || !controls.orderServiceAttachClientNullOption) {
        controls.orderServiceAttachClientNullOptionPosition = false
      }

      return controls
    })

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) return
      const [, err] = await UpdateReservationParameter({
        shopId: shopId.value,
        ...props.configData,
        orderServiceAttachClientCheck: formData.orderServiceAttachClientCheck,
        orderServiceAttachClientNullOption: formData.orderServiceAttachClientCheck ? formData.orderServiceAttachClientNullOption : false,
        orderServiceAttachClientNullOptionPosition: formData.orderServiceAttachClientNullOptionPosition,
        orderServiceAttachClientMultipleSelection: formData.orderServiceAttachClientMultipleSelection,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      initFormData({
        orderServiceAttachClientCheck: props.configData.orderServiceAttachClientCheck,
        orderServiceAttachClientNullOption: props.configData.orderServiceAttachClientNullOption,
        orderServiceAttachClientNullOptionPosition: props.configData.orderServiceAttachClientNullOptionPosition,
        orderServiceAttachClientMultipleSelection: props.configData.orderServiceAttachClientMultipleSelection,
      })
    })

    return {
      formRef,
      formData,
      loading,
      formRules,
      noSpecifyUnitOrderOptionConfig,
      onSubmit,
      showControl,
      displayText,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
